import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Grid from "../styles/Grid";
import breakpoints from "../styles/breakpoints";
import colors from "../styles/colors";
import { fontFG24l, fontFG52m } from "../styles/fonts";

import Layout from "../components/layout";
import Seo from "../components/shared/seo";
import Button from "../components/shared/CTAButton";
import HubspotFormModal from "../components/shared/HubspotFormModal";
import Slices from "../components/shared/Slices";

const CTAButton = styled(Button)`
  height: 50px;
  color: ${colors.white};
  font-size: 16px;
  text-transform: none;
`;

const Title = styled.h1`
  ${fontFG52m};
`;

const Description = styled.h5`
  ${fontFG24l};
  margin: 32px 0;
`;

const TitleBanner = styled.div`
  ${Title} {
    text-align: left;
  }
  ${CTAButton} {
    width: 212px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-right: 156px;
    flex: 1 1 60%;
  }
`;

const CoverImage = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;
  ${CoverImage} {
    width: 100%;
    margin-top: 40px;
  }
  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
    ${CoverImage} {
      margin: 0;
      flex: 1 1 40%;
    }
  }
`;

const Banner = styled.div`
  margin-top: 40px;
  padding: 40px;
  background-color: ${colors.darkBlue};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  ${CTAButton} {
    width: 100%;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 44px 80px;
    flex-direction: row;
    ${CTAButton} {
      width: 212px;
    }
  }
`;

const SlicesContainer = styled.div``;

const Container = styled(Grid)`
  padding-top: 40px;
  ${Header} {
    grid-column: 2 / span 4;
  }
  ${SlicesContainer} {
    grid-column: 2 / span 4;
  }
  ${Banner} {
    grid-column: 2 / span 4;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-top: 90px;
    ${Header} {
      grid-column: 3 / span 10;
    }
    ${SlicesContainer} {
      grid-column: 3 / span 10;
    }
    ${Banner} {
      grid-column: 3 / span 10;
    }
  }
`;

const DownloadEBookButton = (props) => (
  <CTAButton hideArrow {...props}>
    <FormattedMessage id="download_free_copy" />
  </CTAButton>
);

class EBookTemplate extends React.Component {
  state = {
    downloadEBookModalOpen: false,
    hubspotFormCreated: false,
  };

  // componentDidMount() {
  //   // TODO: Add page title param
  //   window.analytics.page("eBook Post");
  // }

  openDownloadEBookModal = () => {
    // window.analytics.track("eBook - Clicked Download");
    this.setState({ downloadEBookModalOpen: true });
  };

  onCancelModal = () => {
    this.setState({ downloadEBookModalOpen: false });
  };

  render() {
    const {
      location,
      pageContext: { locale },
      data,
    } = this.props;
    const { downloadEBookModalOpen } = this.state;
    const pageData = data.prismicEbook.data;

    const coverImageData = getImage(pageData.cover_image);

    return (
      <Layout location={location} locale={locale}>
        <Seo
          title={`${pageData.title} | Workmate`}
          description={pageData.description}
        />
        <Container>
          <Header>
            <TitleBanner>
              <Title>{pageData.title}</Title>
              <Description>{pageData.description}</Description>
              <DownloadEBookButton onClick={this.openDownloadEBookModal} />
            </TitleBanner>
            {coverImageData && (
              <CoverImage>
                <GatsbyImage
                  image={coverImageData}
                  alt={pageData.cover_image.alt}
                />
              </CoverImage>
            )}
          </Header>
          <Slices slices={pageData.body} />
          <HubspotFormModal
            targetId="ebook_page_hubspot_form"
            formId={pageData.hubspot_form_id}
            visible={downloadEBookModalOpen}
            onCancel={this.onCancelModal}
          />
        </Container>
      </Layout>
    );
  }
}
export default EBookTemplate;

export const query = graphql`
  query EBookQuery($slug: String, $localeKey: String) {
    prismicEbook(uid: { eq: $slug }, lang: { eq: $localeKey }) {
      data {
        title
        description
        cover_image {
          alt
          gatsbyImageData
        }
        hubspot_form_id
        cta_banner_text
        body {
          ... on PrismicEbookDataBodyCtaBanner {
            slice_type
            primary {
              cta_button_url
              cta_desktop_primary_button_text
              cta_desktop_secondary_button_text
              cta_hubspot_form_id
              cta_mobile_primary_button_text
              description
              title
            }
          }
          ... on PrismicEbookDataBodyHighlights {
            slice_type
            primary {
              section_title
              section_subtitle
            }
            items {
              bullet_point
            }
          }
        }
      }
    }
  }
`;
